import { x } from "@xstyled/emotion";
import { SystemProps } from "@xstyled/system";
import { Label as AnolisLabel } from "anolis-ui";
import { useField } from "formik";
import React, { FC } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import { useIsError } from "./ValidateWithoutTouch";

export interface LabelProps extends SystemProps {
  name: string;
}

const Label: FC<LabelProps> = ({ children, ...props }) => {
  const [, meta] = useField(props.name);
  const isError = useIsError(meta);

  return (
    <AnolisLabel display="block" {...props}>
      {children}

      {isError && (
        <x.p color="robe" mt="0.25rem" fontSize="0.875rem">
          <FormattedMessage {...meta.error as MessageDescriptor} />
        </x.p>
      )}
    </AnolisLabel>
  );
};

export default Label;
