import { ApiCartModel } from "@ecommerce/core/generated";
import { useRequestContext } from "@ecommerce/core/src";
import { useCart } from "components/context/AppContext/contexts";
import useSWRMutation from "swr/mutation";

export const useCartAction = <Ctx extends {}, Params extends any[]>(action: ((ctx: Ctx, ...p: Params) => Promise<ApiCartModel>)) => {
  const ctx = useRequestContext();
  const { mutateCart } = useCart();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const swr = useSWRMutation<ApiCartModel, unknown, [Ctx, ((ctx: Ctx, ...p: Params) => Promise<ApiCartModel>)], Params>(
    [ctx, action] as any,
  ([c, a], { arg }: { arg: Params }) => mutateCart(a(c, ...arg))
  );

  return {
    ...swr,
    trigger: (...args: Params) => swr.trigger(args)
  };
};
