import { FC } from "react";
import { Formik, Form } from "formik";
import TextField from "components/formik/TextField";
import { object, string } from "yup";
import { Button, TextLink, useModal } from "anolis-ui";
import { x } from "@xstyled/emotion";
import LoginModal from "components/modals/Login";
import { formLocale } from "components/forms/formLocale";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import Success from "components/modals/Success";
import { lostPasswordRequest } from "@ecommerce/core/generated";
import ErrorModal from "components/modals/Error";

export interface LostPasswordProps {
  username: string;
}

const lostPasswordSchema = object().shape({
  email: string().required({ ...formLocale.textRequired })
    .max(150, { ...formLocale.textTooLong })
});

const LostPasswordForm: FC<LostPasswordProps> = ({ username }) => {
  const { formatMessage } = useIntl();
  const [login] = useModal(LoginModal);
  const [close] = useModal();
  const [openSuccessModal] = useModal(Success);
  const [openErrorModal] = useModal(ErrorModal);

  const sendForm = async (email: string) => {
    await lostPasswordRequest({}, { username: email, url: `${window.location.origin}/account/set-password/` })
      .then(() => {
        close();
        openSuccessModal({ message: formatMessage(content.successMessage), permanent: true });
      })
      .catch(() => {
        openErrorModal({ message: formatMessage(content.errorMessage) });
      });
  };

  return (
    <Formik
      initialValues={{ email: username }}
      onSubmit={(values) => sendForm(values.email)}
      validationSchema={lostPasswordSchema}
      validateOnMount
    >

      {({ isValid }) => (
        <Form>
          <x.p mb="2rem" textAlign="center">
            <FormattedMessage
              id="forgottenPassword.description"
              defaultMessage="Please enter your e-mail address and we will send you a link to reset your password."
            />
          </x.p>

          <TextField
            name="email"
            labelText={formatMessage(content.email)}
            required
            autofocus
            inputProps={{
              _input: {
                type: "email"
              }
            }}
          />

          <Button mt="1.5rem" type="submit" disabled={!isValid}>
            {formatMessage(content.button)}
          </Button>

          <x.div mt="1.5rem">
            <TextLink
              v="underlined"
              onClick={() => {
                close();
                login({});
              }}
            >
              {formatMessage(content.link)}
            </TextLink>
          </x.div>
        </Form>
      )}
    </Formik>
  );
};

export default LostPasswordForm;

const content = defineMessages({
  button: {
    id: "forgottenPassword.button",
    defaultMessage: "Send e-mail"
  },
  link: {
    id: "forgottenPassword.link",
    defaultMessage: "Back to login"
  },
  email: {
    id: "forgottenPassword.email",
    defaultMessage: "E-mail address"
  },
  successMessage: {
    id: "forgottenPassword.success",
    // eslint-disable-next-line max-len
    defaultMessage: "E-mail with the new password has been send into the given e-mail address. If you did not receive the e-mail, please try again."
  },
  errorMessage: {
    id: "forgottenPassword.errorModal",
    defaultMessage: "There has been an error while changing the password. Please, try again later."
  }
});
