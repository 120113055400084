import { useUser } from "components/context/AppContext/contexts";

const useUserType = () => {
  const { user } = useUser();

  if (!user) {
    return null;
  } else if (user?.taxRate) {
    return "b2c";
  } else return "b2b";
};

export default useUserType;
