import React, { Fragment, FC } from "react";
import { useFormikContext } from "formik";
import Alert from "components/ui/Alert";

const FormStatus: FC = () => {
  const { status } = useFormikContext();

  return (
    <Fragment>
      {status?.type === "info" && <Alert type="info" text={status.message} />}
      {status?.type === "error" && <Alert type="danger" text={status.message} />}
      {status?.type === "success" && <Alert type="success" text={status.message} />}
      {status?.type === "warning" && <Alert type="warning" text={status.message} />}
    </Fragment>
  );
};

export default FormStatus;
