import { ApiCategory, ApiProductPictureModel } from "@ecommerce/core/generated";
import { CARD_PRODUCT_SIZE, getImageUrl, Localizable } from "@ecommerce/core/src";
import { x } from "@xstyled/emotion";
import { Card as AnolisCard } from "anolis-ui";
import Price from "components/ui/Price";
import NextImage from "next/image";
import Link from "next/link";
import { FC, Fragment, ReactNode, useMemo } from "react";
import { useLocal } from "utils/useLocal";

export interface ProductCardProps {
  id?: number;
  name: Localizable<string>;
  categories?: ApiCategory[];
  price?: number;
  slug?: Localizable<string>;
  cover?: ApiProductPictureModel;
  direction?: "column" | "row";
  category?: string;
  categoriesInline?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({ name, categories, price, slug, cover, direction = "column", category, categoriesInline }) => {
  const { localize } = useLocal();
  const categoriesToShow = useMemo(() => categories?.filter(c => c.type === "menu"), [categories]);

  const link = (ch: ReactNode) => slug
    ? <Link href={category ? `/product/${localize(slug)!}?category=${category}` : `/product/${localize(slug)!}`} passHref>{ch}</Link>
    : <Fragment>{ch}</Fragment>;

  return link(
    <AnolisCard
      as="a"
      v="elevated"
      media={cover ? (
        <x.div
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ aspectRatio: "220/230" }}
        >
          <NextImage
            src={getImageUrl(cover, CARD_PRODUCT_SIZE)}
            width={272}
            height={220}
            placeholder="blur"
            objectFit="contain"
            blurDataURL={getImageUrl(cover, CARD_PRODUCT_SIZE)}
          />
        </x.div>
      ) : null}
      _media={{
        display: "block",
        justifyContent: "center",
        p: "1rem",
        pb: 0,
        ...direction === "row" && {
          w: "4rem",
          h: "4rem",
          p: 0,
          flex: "0 0 auto"
        }
      }}
      _body={{ h: "100%", pb: "1rem" }}
      footer={price ? <Price calculateTax value={price} fontWeight={direction === "row" ? 600 : 700} /> : <Fragment />}
      _footer={{ pt: 0, pb: "2rem", flex: "0 0 auto" }}
      justifyContent="flex-start"
      alignItems="center"
      cursor="pointer"
      textDecoration="none"
      flexDirection={direction}
      {...direction === "row" && {
        maxW: "none",
        p: "0.5rem 1.5rem",
        boxShadow: "none",
        bg: { hover: "rgba(147, 149, 152, 0.1)" },
        _body: {
          px: "1rem",
          py: 0,
          flexDirection: "column-reverse",
          w: "100%"
        },
        _footer: {
          flex: "0 0 auto",
          p: 0
        }
      }}
      data-group
    >
      <x.h3
        textAlign={direction === "row" ? "left" : "center"}
        color={direction === "column" ? { groupHover: "robe" } : {}}
        transition="color 300ms"
        style={{ wordBreak: "break-word" }}
        {...direction === "row" && {
          fontSize: "1rem",
          lineHeight: "1.3125rem"
        }}
      >
        {localize(name)}
      </x.h3>

      {categoriesInline ? (
        <x.p
          mt={direction === "row" ? 0 : "1"}
          fontSize="0.75rem"
          lineHeight="1.125rem"
          textAlign={direction === "row" ? "left" : "center"}
          color="gray"
          fontWeight={600}
        >
          {categoriesToShow?.map((c, i) =>
            (
              <Fragment key={c.id}>
                {c.name}
                <x.span padding="0 .35rem">
                  {i === categoriesToShow.length - 1 ? "" : "|"}
                </x.span>
              </Fragment>
            ))}
        </x.p>
      ) : categoriesToShow?.map((c) => (
        <x.p
          mt={direction === "row" ? 0 : "1"}
          fontSize={{ _: "0.75rem", md: "0.9rem" }}
          lineHeight="1.125rem"
          textAlign={direction === "row" ? "left" : "center"}
          color="gray"
          fontWeight={600}
          key={c.id}
        >
          {c.name}
        </x.p>
      ))}
    </AnolisCard>
  );
};

export default ProductCard;
