import { ApiCurrency, ApiCurrencySymbolPosition, ApiPriceModel } from "@ecommerce/core/generated";
import { SystemProps as XStyledSystemProps, x } from "@xstyled/emotion";
import { Txt } from "anolis-ui";
import { TxtProps } from "anolis-ui/dist/components/Typography/Txt";
import { useSharedProps, useTaxRate } from "components/context/AppContext/contexts";
import { useRouter } from "next/router";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import useUserType from "utils/useUserType";

type PriceProps = ({
  value: number;
  calculateTax?: boolean;
} | {
  value: ApiPriceModel;
  calculateTax?: undefined;
}) & {
  numberOnly?: boolean;
  showTaxInfo?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  symbolPosition?: ApiCurrencySymbolPosition;
  currency?: ApiCurrency;
  upperLimit?: number;
  displayUpperLimit?: string;
  type?: TxtProps["t"];
  as?: any;
} & XStyledSystemProps;

const Price: FC<PriceProps> = ({
  value,
  numberOnly = false,
  showTaxInfo = false,
  calculateTax = false,
  minimumFractionDigits,
  maximumFractionDigits,
  symbolPosition,
  currency,
  upperLimit,
  displayUpperLimit,
  type = "p",
  ...props
}) => {
  const { locale } = useRouter();
  const { currency: defaultCurrency } = useSharedProps();
  const rate = useTaxRate(calculateTax);

  const userType = useUserType();

  const shouldCalculateTax = userType !== "b2b" && calculateTax;
  const loading = shouldCalculateTax && rate === undefined;

  currency = currency ?? defaultCurrency;

  const price = useMemo(() =>
    resolvePrice(value, shouldCalculateTax ? rate ?? 0 : 0),
  [rate, shouldCalculateTax, value]);

  if (price.withTax === 0 && !numberOnly) {
    return (
      <Txt t={type} as="div" textAlign="center" color="success" fontWeight="700" {...props}>
        Free
      </Txt>
    );
  }

  if (!currency) {
    return (
      <Txt t={type} as="div" textAlign="center" color="red" fontWeight="700" {...props}>
        <FormattedMessage id="components.price.error" defaultMessage="Error" />
      </Txt>
    );
  }

  const formattedValue = new Intl.NumberFormat(locale, {
    minimumFractionDigits: minimumFractionDigits ?? 0,
    maximumFractionDigits: maximumFractionDigits ?? 2
  })
    .format(price.withTax);

  if (!symbolPosition) symbolPosition = currency.symbolPosition;

  return (
    <Txt t={type} as="div" textAlign="center" fontWeight="700" {...props as any}>
      {symbolPosition === "prefix" && <x.span color="robe">{currency.symbol}&nbsp;</x.span>}
      <x.span color={!loading ? undefined : "#939598"}>{upperLimit && price.withTax > upperLimit ? `${displayUpperLimit ?? `< ${upperLimit}`}` : formattedValue}</x.span>
      {symbolPosition === "postfix" && <x.span color="robe">&nbsp;{currency.symbol}</x.span>}
      {!loading && userType !== "b2b" && showTaxInfo && (
        <x.span color="#939598" ml="0.5rem" fontWeight={500}>
          <FormattedMessage id="components.price.vat" defaultMessage="(incl. VAT)" />
        </x.span>
      )}
    </Txt>
  );
};

export default Price;

const resolvePrice = (value: number | ApiPriceModel, taxRate: number): ApiPriceModel => {
  if (typeof value === "number") {
    return {
      withTax: Math.ceil(value * (1 + taxRate / 100) * 100) / 100,
      tax: value * (taxRate / 100),
      taxRate
    };
  }

  return value;
};
