import { FC, ReactElement } from "react";
import { SystemProps, x } from "@xstyled/emotion";

interface AlertProps extends SystemProps {
  text: string | ReactElement;
  type?: "success" | "danger" | "info" | "warning";
}

const Alert: FC<AlertProps> = ({ text, type, ...props }): ReactElement => {
  const defaultProps = {
    display: "block",
    border: 1,
    padding: 3,
    marginBottom: 3,
    bg: "blue-300",
    color: "blue-500",
    borderColor: "blue-500",
    fontFamily: "Avenir"
  };

  let colorVariantProps;

  switch (type) {
    case "success":
      colorVariantProps = { bg: "green-300", color: "green-600", borderColor: "green-500" };
      break;
    case "danger":
      colorVariantProps = { bg: "red-300", color: "red-600", borderColor: "red-500" };
      break;
    case "warning":
      colorVariantProps = { bg: "yellow-300", color: "yellow-600", borderColor: "yellow-500" };
      break;
    case "info":
      colorVariantProps = { bg: "blue-300", color: "blue-500", borderColor: "blue-50" };
  }

  return (
    <x.div {...defaultProps} {...colorVariantProps} {...props}>
      {text}
    </x.div>
  );
};

export default Alert;
