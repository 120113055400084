import { MutableRefObject, useEffect } from "react";

const useOutsideAlerter = (ref: MutableRefObject<any>, setOpen: (x: boolean) => unknown) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => !ref?.current?.contains(event.target) && setTimeout(() => setOpen(false), 100);
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, setOpen]);
};

export default useOutsideAlerter;
