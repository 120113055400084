import { removeFromCartRequest } from "@ecommerce/core/generated";
import { x } from "@xstyled/emotion";
import { Button, Icon } from "anolis-ui";
import CartCard from "components/cards/CartCard";
import { isGhostCart, useCart } from "components/context/AppContext/contexts";
import DumpIcon from "components/icons/24/dump.svg";
import EmptyBagIcon from "components/icons/32/empty-bag.svg";
import Price from "components/ui/Price";
import Link from "next/link";
import React, { FC, Fragment, useEffect, useMemo, useRef } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import changeCartItemStructure from "utils/changeCartItemStructure";
import { useCartAction } from "utils/useCartAction";
import useOutsideAlerter from "utils/useOutsideAlerter";
import useUserType from "utils/useUserType";

const CartDropdown: FC<{ setOpen: (x: boolean) => unknown }> = ({ setOpen }) => {
  const { cart } = useCart();
  const { trigger: removeFromCart } = useCartAction(removeFromCartRequest);
  const wrapperRef = useRef(null);
  const userType = useUserType();
  useOutsideAlerter(wrapperRef, setOpen);

  useEffect(() => () => void setOpen(false), [setOpen]);
  const items = useMemo(() => isGhostCart(cart) ? undefined : changeCartItemStructure(cart.items), [cart]);

  const { formatMessage } = useIntl();

  return (
    <x.div
      position="fixed"
      top="4rem"
      w={{ _: "100%", md: "26rem" }}
      bg="#fff"
      boxShadow="0 0.5rem 1rem 0 rgba(30, 30, 30, 0.1), 0 1rem 2rem 0 rgba(30, 30, 30, 0.1)"
      right={0}
      backgroundColor="#fff"
      maxHeight="calc(100vh - 4rem)"
      display="flex"
      flexDirection="column"
      borderTop="1px solid rgba(147, 149, 152, 0.5)"
      zIndex={100}
      ref={wrapperRef}
    >
      {!items || items.length === 0 ? (
        <x.div
          my="3rem"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <x.div>
            <Icon svg={<EmptyBagIcon />} />
          </x.div>

          <x.p mt="1rem">
            <FormattedMessage id="components.topBar.cartDropdown.empty" defaultMessage="Your bag is empty 😭" />
          </x.p>
        </x.div>
      ) : (
        <Fragment>
          <x.div maxH="100%" overflow="auto">
            {items.map((p, k) => (
              <x.div display="flex" alignItems="center" key={p.id} pb="1rem" pr="1rem" pl="1rem" pt={k === 0 ? "1rem" : 0}>
                <CartCard
                  name={p.productVariant.product!.name}
                  category={p.categories}
                  price={p.price}
                  parameters={p.productVariant.parameters}
                  quantity={p.quantity}
                  cover={p.productVariant.picture}
                  slug={p.productVariant.product?.slug}
                />

                <Icon
                  onClick={() => removeFromCart(p.productVariantId)}
                  svg={<DumpIcon />}
                  fill="gray"
                  fillHover="black"
                  ml="1rem"
                  cursor="pointer"
                />
              </x.div>
            ))}
          </x.div>
          <x.div
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px="1rem"
            py="0.875rem"
            bg="rgba(255, 255, 255, 1)"
            boxShadow="0 0.5rem 1rem 0 rgba(30, 30, 30, 0.1), 0 1rem 2rem 0 rgba(30, 30, 30, 0.1)"
          >
            <x.p fontSize="0.875rem" lineHeight="1.3125rem" color="gray">
              <x.strong color="black" fontWeight={600}>
                <FormattedMessage id="components.topBar.cartDropdown.price" defaultMessage="Total" />
              </x.strong>{" "}
              {userType !== "b2b" && formatMessage(content.vat)}
            </x.p>

            <x.p>
              {cart.priceSummary && <Price value={cart.priceSummary.total} fontWeight={700} />}
            </x.p>
          </x.div>

          <Link href="/cart" passHref>
            <Button w="100%" onClick={() => setOpen(false)}>
              {formatMessage(content.button)}
            </Button>
          </Link>
        </Fragment>
      )}
    </x.div>
  );
};

export default CartDropdown;

const content = defineMessages({
  button: {
    id: "components.topBar.cartDropdown.button",
    defaultMessage: "Checkout"
  },
  vat: {
    id: "components.topBar.cartDropdown.vat",
    defaultMessage: "(incl. VAT)"
  }
});
