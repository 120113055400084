import { defineMessages } from "@formatjs/intl";

export const formLocale = defineMessages({
  emailWrong: { id: "formLocale.emailWrong", defaultMessage: "E-mail is in wrong format." },
  textRequired: { id: "formLocale.textRequired", defaultMessage: "This field is required." },
  textTooLong: { id: "formLocale.textTooLong", defaultMessage: "Text is too long." },
  selectRequired: { id: "formLocale.selectRequired", defaultMessage: "Please choose one of the options." },
  passwordMinLength: { id: "formLocale.passwordMinLength", defaultMessage: "Password is too short." },
  passwordChanged: { id: "formLocale.passwordChanged", defaultMessage: "Your password has been changed." },
  agreementRequired: { id: "formLocale.agreementRequired", defaultMessage: "Agreement is required." },
  wrongFormat: { id: "formLocale.wrongFormat", defaultMessage: "Input is in wrong format." },
  noStreetNumber: { id: "formLocale.noStreetNumber", defaultMessage: "Add street number" },
  specialCharacters: { id: "formLocale.specialCharacters", defaultMessage: "Input contains special characters." }
});
