import { ApiPriceModel, ApiProductPictureModel } from "@ecommerce/core/generated";
import { CAROUSEL_PRODUCT_SIZE, getImageUrl, Localizable } from "@ecommerce/core/src";
import { x } from "@xstyled/emotion";
import { Card as AnolisCard, CardProps } from "anolis-ui";
import Price from "components/ui/Price";
import NextImage from "next/image";
import Link from "next/link";
import React, { FC, Fragment, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { useLocal } from "utils/useLocal";

export interface CartCardProps extends Omit<CardProps, "title"> {
  name?: Localizable<string>;
  subName?: string;
  category?: string;
  price?: ApiPriceModel;
  quantity?: number;
  parameters?: string[];
  cover?: ApiProductPictureModel;
  slug?: Localizable<string>;
  inCart?: boolean;
  cartPage?: boolean;
}

const CartCard: FC<CartCardProps> = (
  {
    name,
    subName,
    category,
    price,
    quantity,
    parameters,
    inCart,
    cover,
    media,
    slug,
    ...props
  }) => {
  const { localize } = useLocal();
  const card = (
    <AnolisCard
      media={cover ? <Image alt={localize(name)!} slug={localize(slug)} cover={cover} /> : media}
      _media={{
        w: inCart ? { _: "4rem", md: "6rem" } : "4rem",
        h: inCart ? { _: "4rem", md: "6rem" } : "4rem",
        p: 0,
        flex: "0 0 auto",
        justifyContent: "center",
        alignSelf: "center"
      }}
      _body={{
        h: "100%",
        px: "1rem",
        py: 0,
        flexDirection: "column",
        w: "100%"
      }}
      footer={price ? <Price value={price} fontWeight={700} fontSize="0.875rem" lineHeight="1.3125rem" /> : <Fragment />}
      _footer={{ p: 0, flex: "0 0 auto" }}
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="row"
      textDecoration="none"
      maxW="none"
      boxShadow="none"
      data-group
      {...props}
    >
      {category && (
        <x.p
          mt={0}
          fontSize="0.75rem"
          lineHeight="1.125rem"
          textAlign="left"
          color="gray"
          fontWeight={600}
        >
          {category}
        </x.p>
      )}

      {slug ? (
        <Link href={{ pathname: "/product/[slug]", query: { slug: localize(slug) } }} passHref>
          <a style={{ textDecoration: "none" }}>
            <x.p
              fontSize={inCart ? "1rem" : "0.875rem"}
              lineHeight="1.3125rem"
              fontWeight={600}
              color="black"
            >
              {localize(name)}
              {subName && (
                <x.small fontWeight={400} display="block" fontSize="0.75rem" lineHeight="1.125rem">
                  {subName}
                </x.small>
              )}
            </x.p>
          </a>
        </Link>
      ) : (
        <x.p
          fontSize={inCart ? "1rem" : "0.875rem"}
          lineHeight="1.3125rem"
          fontWeight={600}
          color="black"
        >
          {localize(name)}
          {subName && (
            <x.small fontWeight={400} display="block" fontSize="0.75rem" lineHeight="1.125rem">
              {subName}
            </x.small>
          )}
        </x.p>
      )}
      {(quantity || parameters) && (
        <x.div display="flex" flexWrap="wrap">
          <x.span fontSize="0.75rem" lineHeight="1.125rem" mr="0.5rem" whiteSpace="nowrap" fontFamily="avenir">
            <x.strong fontWeight={600}>
              {parameters ? parameters.join(", ") : null}
            </x.strong>
          </x.span>

          {quantity && (
            <x.p flexBasis="100%" fontSize="0.75rem" lineHeight="1.125rem" whiteSpace="nowrap">
              <FormattedMessage id="product.amount" defaultMessage="Amount:" />{" "}
              <x.strong fontWeight={600}>{quantity}</x.strong>
            </x.p>
          )}
        </x.div>
      )}
    </AnolisCard>
  );
  return card;
};
export default CartCard;

const Image: FC<{slug?: string; cover: ApiProductPictureModel; alt: string}> = ({ slug, cover }) =>
  slug ? (
    <Link href={{ pathname: "/product/[slug]", query: { slug } }} passHref>
      <a>
        <NextImage
          src={getImageUrl(cover, CAROUSEL_PRODUCT_SIZE)}
          width={128}
          height={128}
          placeholder="blur"
          blurDataURL={getImageUrl(cover, CAROUSEL_PRODUCT_SIZE)}
          objectFit="contain"
        />
      </a>
    </Link>
  ) : (
    <NextImage
      src={getImageUrl(cover, CAROUSEL_PRODUCT_SIZE)}
      width={128}
      height={128}
      placeholder="blur"
      blurDataURL={getImageUrl(cover, CAROUSEL_PRODUCT_SIZE)}
      objectFit="contain"
    />
  );
