import { FC } from "react";
import { Spinner } from "anolis-ui";
import { SystemProps, x } from "@xstyled/emotion";

const Loading: FC<SystemProps> = p => {
  return (
    <x.div w="100%" textAlign="center" padding={10} display="flex" alignItems="center" justifyContent="center" {...p}>
      <Spinner />
    </x.div>
  );
};

export default Loading;
