import { x } from "@xstyled/emotion";
import { Container, TextLink } from "anolis-ui";
import { MenuContext } from "components/context/MenuContext";
import NavLink from "components/ui/TopBar/NavLink";
import Link from "next/link";
import React, { FC, Fragment, useCallback, useContext, useRef, useState } from "react";
import useDelayedAction from "utils/useDelayedAction";
import { HeaderState } from "components/ui/TopBar";

const Navigation: FC<{topBarState: HeaderState}> = ({ topBarState }) => {
  const menuContext = useContext(MenuContext);
  const [navState, setNavState] = useState<string|null>(null);
  const callback = useCallback(() => setNavState(null), []);
  const [close, prevent] = useDelayedAction(callback, 300);
  const [longClose, longPrevent] = useDelayedAction(callback, 2000);
  const timeRef = useRef<NodeJS.Timeout>(null!);

  return (
    <x.div
      onMouseLeave={() => {
        clearInterval(timeRef.current);
        close();
      }}
      onMouseEnter={prevent}
      h="100%"
    >
      <x.div
        as="nav"
        display={{ _: "none", lg: "flex" }}
        justifyContent="center"
        h="100%"
        whiteSpace="nowrap"
      >
        {topBarState !== "search" && menuContext?.menu.map((menuItem) => (
          <NavLink
            key={menuItem.data.id}
            active={navState === menuItem.data.slug}
            onMouseEnter={() => void (timeRef.current = setTimeout(() => setNavState(menuItem.data.slug), 300))}
            categorySlug={menuItem.data.slug}
            name={menuItem.data.name}
            onClick={() => close()}
          />
        ))}
      </x.div>
      <x.div
        position="absolute"
        top="4rem"
        bg="black"
        w="100%"
        h="auto"
        py="4rem"
        left={0}
        onMouseEnter={longPrevent}
        onMouseLeave={() => longClose()}
        opacity={navState !== null ? 1 : 0}
        pointerEvents={navState !== null ? "all" : "none"}
        transition="all 300ms"
      >
        <Container>
          <x.div
            display="grid"
            gridTemplateColumns={{ lg: "repeat(4, 1fr)", xl: "repeat(5, 1fr)" }}
            gap="2em 1em"
            whiteSpace="nowrap"
          >
            {menuContext?.menu.map((mainMenuItem) => (
              <Fragment key={mainMenuItem.data.id}>
                {navState === mainMenuItem.data.slug && (
                  <Fragment>
                    {mainMenuItem?.children.map((menuItem) => (
                      <x.div
                        key={menuItem.data.id}
                        {...{
                          flexBasis: {
                            _: "100%",
                            xs: "calc(50% - 3rem)",
                            sm: "calc(33.33% - 3rem)",
                            md: "calc(25% - 3rem)",
                            lg: "calc(16.667% - 3rem)"
                          }
                        }}
                        mx="1rem"
                        display="flex"
                        flexDirection="column"
                      >
                        <Link href={menuItem.data.slug && `/category/${menuItem.data.slug}`} passHref>
                          <TextLink
                            fontSize="1rem"
                            fontWeight={700}
                            letterSpacing={0}
                            lineHeight="1.5rem"
                            textTransform="uppercase"
                            color="#fff"
                            onClick={() => close()}
                          >
                            {menuItem.data.name}
                          </TextLink>
                        </Link>

                        <x.div mt="0.5rem">
                          {menuItem?.children.map((child) => (
                            <Link key={child.data.id} href={menuItem.data.slug && `/category/${child.data.slug}`} passHref>
                              <TextLink
                                mt="0.5rem"
                                display="flex"
                                fontSize="1rem"
                                lineHeight="1.5rem"
                                fontWeight={400}
                                onClick={() => close()}
                              >
                                {child.data.name}
                              </TextLink>
                            </Link>
                          ))}
                        </x.div>
                      </x.div>
                    ))}
                  </Fragment>
                )}
              </Fragment>
            ))}
          </x.div>
        </Container>
      </x.div>
    </x.div>
  );
};

export default Navigation;
