import styled, { x } from "@xstyled/emotion";
import NextImage, { StaticImageData } from "next/image";
import Link from "next/link";
import { FC } from "react";
import { Url } from "url";

const InstaCard: FC<InstaCardProps> = (
  {
    image,
    alt,
    target = "_blank",
    ...props
  }
) => {
  return (
    <Link passHref {...props}>
      <a target={target} rel="noopener noreferrer">
        <InstaCardStyle>
          {image && (
            <NextImage
              src={image}
              alt={alt}
              layout="fill"
              style={{ filter: "saturate(0%)", aspectRatio: "1/1" }}
            />
          )}

          <x.div position="absolute" top={0} bottom={0} left={0} right={0} backgroundColor="rgba(211, 0, 49, .4)" />
        </InstaCardStyle>
      </a>
    </Link>
  );
};

export default InstaCard;

export type InstaCardProps = {
  image?: StaticImageData;
  alt?: string;
  target?: string;
  href: Url | string;
};

const InstaCardStyle = styled.span`
  display: block;
  aspect-ratio: 1/1;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  img {
    transition: filter 600ms;
    
    & + div {
      transition: opacity 600ms;
    }
  }

  &:hover {
    
    img {
      filter: none !important;
      
      & + div {
        opacity: 0;
      }
    }
  }
`;
