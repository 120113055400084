import { x } from "@xstyled/emotion";
import { Typography } from "anolis-ui";
import Link from "next/link";
import { FC, Fragment } from "react";
import { FormattedMessage } from "react-intl";

export interface CategoryCardProps {
  id?: number;
  name: string;
  slug: string;
  path: string[];
}

const CategoryCard: FC<CategoryCardProps> = ({ slug, path }) => {
  return (
    <Link href={`/category/${slug}`} passHref>
      <x.div
        cursor="pointer"
        p="0.5rem 1.5rem"
        color={{ _: "gray" }}
        bg={{ _: "rgba(200, 200, 200, 0.1)", hover: "rgba(61, 61, 61, 0.1)" }}
        transition="color 300ms"
        fontSize=".9rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontFamily="Avenir"
      >
        <x.span mr={1}>
          <FormattedMessage id="components.topBar.searchDropdown.categoryResult" defaultMessage="Category:" />
        </x.span>
        {path.map((p, i) => (
          <Fragment key={i}>
            {i !== 0 && (
              <Typography
                mx="0.5rem"
                fontSize="0.75rem"
                fontWeight={600}
                letterSpacing={0}
                lineHeight="1.125rem"
                color="#e00034a8"
              >
                ➤
              </Typography>
            )}
            <x.span
              color={i === path.length - 1 ? "black" : "gray"}
              fontWeight={i === path.length - 1 ? "bold" : 600}
            >
              {p}
            </x.span>
          </Fragment>
        ))}
      </x.div>
    </Link>
  );
};

export default CategoryCard;
