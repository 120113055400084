import { ElementType, FC, ReactNode } from "react";
import { Container, ContainerProps } from "anolis-ui";
import { SystemProps } from "@xstyled/system";
import { x } from "@xstyled/emotion";

export type ContentProps = {
  leftContent?: ReactNode | ReactNode[];
  middleContent: ReactNode | ReactNode[];
  rightContent?: ReactNode | ReactNode[];
  _container?: ContainerProps;
  _grid?: SystemProps;
};

export interface NavigationProps extends ContentProps, SystemProps {
  as?: ElementType;
}

const Header: FC<NavigationProps> = ({
  leftContent,
  middleContent,
  rightContent,
  _container,
  _grid,
  ...props
}) => (
  <x.div {...props}>
    <Container {..._container}>
      {leftContent}
      {middleContent}
      {rightContent}
    </Container>
  </x.div>
);

export default Header;
