import { x } from "@xstyled/emotion";
import { Modal, useModal } from "anolis-ui";
import RobeHeading from "components/ui/RobeHeading";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  message: string;
}

const ErrorModal: FC<Props> = ({ message }) => {
  return (
    <Modal
      header={(
        <RobeHeading t="h2" fontWeight={700}>
          <FormattedMessage id="components.dialog.error" defaultMessage="Error" />
        </RobeHeading>
      )}
      px="2rem"
    >
      <x.p style={{ textAlign: "center" }}>{message}</x.p>
    </Modal>
  );
};

export default ErrorModal;
