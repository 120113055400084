import { Dispatch, FC, SetStateAction } from "react";
import RobeHeading from "components/ui/RobeHeading";
import { Modal } from "anolis-ui";
import LoginForm from "components/forms/Login";
import { FormattedMessage } from "react-intl";
// import CreateAccountBeforeOrder from "components/ui/CreateAccountBeforeOrder";

interface Props {
  setNavOpen?: Dispatch<SetStateAction<boolean>>;
}

const LoginModal: FC<Props> = ({ setNavOpen }) => {
  return (
    <Modal
      header={(
        <RobeHeading t="h3" fontWeight={700}>
          <FormattedMessage id="components.dialog.login" defaultMessage="Login" />
        </RobeHeading>
      )}
      px="2rem"
    >
      <LoginForm setNavOpen={setNavOpen} />
    </Modal>
  );
};

export default LoginModal;
