import { x } from "@xstyled/emotion";
import { Modal, useModal } from "anolis-ui";
import RobeHeading from "components/ui/RobeHeading";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  message: string;
  permanent?: boolean;
}

const SuccessModal: FC<Props> = ({ message, permanent = false }) => {
  const [closeSuccess] = useModal();
  !permanent && setTimeout(() => closeSuccess(), 3000);
  return (
    <Modal
      header={(
        <RobeHeading t="h2" fontWeight={700}>
          <FormattedMessage id="components.dialog.success" defaultMessage="Success" />
        </RobeHeading>
      )}
      px="2rem"
    >
      <x.p style={{ textAlign: "center" }}>{message}</x.p>
    </Modal>
  );
};

export default SuccessModal;
