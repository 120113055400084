import { useUp, x } from "@xstyled/emotion";
import { Icon, Spinner, useModal } from "anolis-ui";
import { useCart, useUser } from "components/context/AppContext/contexts";
import { MenuContext } from "components/context/MenuContext";
import Search, { useSearchClickOutside } from "components/forms/Search";
import DropIcon from "components/icons/12/drop.svg";
import BagIcon from "components/icons/24/bag.svg";
import SearchIcon from "components/icons/24/search.svg";
import UserIcon from "components/icons/24/user.svg";
import LoginModal from "components/modals/Login";
import BurgerMenu from "components/ui/BurgerMenu";
import Header from "components/ui/Header";
import IconBox from "components/ui/IconBox";
import Price from "components/ui/Price";
import CartDropdown from "components/ui/TopBar/dropdowns/Cart";
import LocaleDropdown from "components/ui/TopBar/dropdowns/LocaleDropdown";
import MobileNavigation from "components/ui/TopBar/MobileNavigation";
import Navigation from "components/ui/TopBar/Navigation";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, Fragment, memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import getCartItemCount from "utils/getCartItemCount";
import useUserType from "utils/useUserType";

export type HeaderState = "menu" | "search" | "localeSwitcher" | null;

const TopBar: FC = () => {
  const { locale } = useRouter();
  const [navigationOpen, setNavigationOpen] = useState<boolean>(false);
  const [topBarState, setTopBarState] = useState<HeaderState>(null);
  const close = useCallback(() => setTopBarState(null), []);
  const upLg = useUp("lg");
  const [openLogin] = useModal(LoginModal);
  const [openLocaleMenu, setOpenLocaleMenu] = useState<boolean>(false);
  const { cart, isValidating } = useCart();
  const user = useUser();
  const userType = useUserType();
  const menu = useContext(MenuContext);
  const searchElement = useRef<HTMLDivElement>(null);
  const buttonElement = useRef<HTMLDivElement>(null);

  const handleToggleLocaleMenu = () => {
    if (!openLocaleMenu) {
      menu?.setCartMenuOpen(false);
    }
    setOpenLocaleMenu(!openLocaleMenu);
  };

  const handleToggleCartMenu = () => {
    menu?.setCartMenuOpen(!menu?.cartMenuOpen);
  };

  useSearchClickOutside(searchElement, buttonElement, close);

  useEffect(() => {
    document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");

    window.addEventListener("resize", () => {
      document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
    });
  }, []);

  return (
    <Header
      as="header"
      position="sticky"
      top={0}
      h="4rem"
      bg="#fff"
      zIndex={2}
      boxShadow="0 0.5rem 1rem 0 rgba(30, 30, 30, 0.1), 0 1rem 2rem 0 rgba(30, 30, 30, 0.1)"
      leftContent={(
        <Link href="/" passHref>
          <x.img src="/static/img/robe-merch.svg" alt="" cursor="pointer" />
        </Link>
      )}
      middleContent={(
        <Fragment><MobileNavigation open={navigationOpen} setOpen={setNavigationOpen} />
          {topBarState === "search" && (
            <Search
              searchElementRef={searchElement}
              withResults
              autoFocus
              inTopBar
              zIndex={2000}
            />
          )}
          <Navigation topBarState={topBarState} />
        </Fragment>
      )}
      rightContent={(
        <x.div display="flex" justifyContent="flex-end">
          <IconBox
            ref={buttonElement}
            borderRight={{ lg: "1px solid rgba(147, 149, 152, 0.5)" }}
            onClick={(e: any) => {
              (e as MouseEvent).stopPropagation();
              topBarState === "search" ? close() : setTopBarState("search");
            }}
            {...topBarState === "search" && {
              px: { _: "0.75rem", lg: "auto" },
              ...!upLg && {
                bg: "#000",
                fill: "#fff",
                fillHover: "#fff"
              }
            }}
          >
            {topBarState === "search" && upLg ? <BurgerMenu isOpen /> : <SearchIcon />}
          </IconBox>

          <x.div display="flex" position="relative">

            <x.div display={{ _: "none", lg: "flex" }} position="relative">
              {user.loggedIn ? (
                <x.div maxH="4rem">
                  <Link href="/account" passHref>
                    <IconBox
                      as="a"
                      display="flex"
                      borderRight={{ lg: "1px solid rgba(147, 149, 152, 0.5)" }}
                      badge="✔"
                      ml="1.1rem"
                      mt="-1rem"
                    >
                      <UserIcon />
                    </IconBox>
                  </Link>
                </x.div>
              ) : (
                <IconBox
                  as="a"
                  display="flex"
                  borderRight={{ lg: "1px solid rgba(147, 149, 152, 0.5)" }}
                  onClick={openLogin}
                >
                  <UserIcon />
                </IconBox>
              )}
            </x.div>

            <IconBox
              borderRight={{ lg: "1px solid rgba(147, 149, 152, 0.5)" }}
              badge={getCartItemCount(cart)}
              onClick={handleToggleCartMenu}
              isActive={menu?.cartMenuOpen}
              ml="1rem"
              mt="-1rem"
            >
              <BagIcon />
            </IconBox>

            {userType === "b2b" && user.user?.customer?.credit && (
              <IconBox
                as="a"
                display={{ _: "none", lg: "flex" }}
                borderRight={{ lg: "1px solid rgba(147, 149, 152, 0.5)" }}
                fontSize={{ _: ".61rem", sm: ".875rem" }}
                flexDirection="column"
                justifyContent="center"
                padding={0}
                cursor="auto"
              >
                <x.div fontFamily="avenir" fontWeight={600}><FormattedMessage id="topBar.credit" defaultMessage="Credit:" /></x.div> {" "}
                <Price
                  fontSize={{ _: ".61rem", sm: ".875rem" }}
                  value={Math.floor(user.user.customer.credit)}
                  whiteSpace="nowrap"
                  upperLimit={999999}
                  displayUpperLimit="< 999,999"
                />
              </IconBox>
            )}

            {isValidating && (
              <x.div
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                pointerEvents="none"
                bg="white"
                opacity="0.6"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner color="robe" />
              </x.div>
            )}
          </x.div>

          {menu?.cartMenuOpen && createPortal(<CartDropdown setOpen={menu?.setCartMenuOpen} />,
            document.getElementById("portal-window") as Element)}

          <BurgerMenu
            isOpen={navigationOpen}
            onClick={() => setNavigationOpen(!navigationOpen)}
          />

          <x.div
            onClick={handleToggleLocaleMenu}
            cursor="pointer"
            p="1.25rem"
            display={{ _: "none", lg: "flex" }}
            alignItems="center"
            whiteSpace="nowrap"
            backgroundColor={openLocaleMenu ? "black" : "white"}
          >
            <x.span color={openLocaleMenu ? "white" : "black"} fontWeight="bold">
              {locale?.toUpperCase()}
            </x.span>

            {/* TODO: uncomment with currency switch feature
            <x.span color="gray" mx="0.25rem">/</x.span>
            <x.span fontWeight="bold" color="robe">
              {currency.symbol}
            </x.span> */}
            <x.span ml="0.5rem">
              <Icon
                svg={<DropIcon />}
                transform={openLocaleMenu ? "rotate(180deg)" : "rotate(0)"}
                fill={openLocaleMenu ? "white" : "black"}
                transition="transform 300ms"
              />
            </x.span>
          </x.div>

          {openLocaleMenu && createPortal(<LocaleDropdown setOpen={setOpenLocaleMenu} />,
            document.getElementById("portal-window") as Element)}
        </x.div>
      )}
      _container={{
        v: "fluid",
        display: "grid",
        gridTemplateColumns: { _: "1fr 1px 1fr", lg: "repeat(3, 1fr)" },
        alignItems: "center",
        justifyContent: "center",
        h: "100%",
        pr: { _: "0", lg: "1rem" },
        pl: { _: "0.3rem", lg: "1rem" }
      }}
    />
  );
};

export default memo(TopBar);
