import { ApiCartItemModel, ApiCategory } from "@ecommerce/core/generated";

const changeCartItemStructure = (cartItems: ApiCartItemModel[]) => cartItems.map(c => ({
  ...c,
  categories: categoryFilter(c.productVariant.categories),
  productVariant: {
    ...c.productVariant,
    parameters: c.productVariant?.parameters === "null"
      ? []
      : Object.values(c.productVariant!.parameters.general ?? {})
  }
}));

export default changeCartItemStructure;

export const categoryFilter = (categories: ApiCategory[]) =>
  categories.filter(category => category.type === "menu").map(category => category.name).join(" | ");
