import { FieldMetaProps } from "formik";
import { createContext, FC, useContext } from "react";

const ValidateWithoutTouchContext = createContext(false);

export const ValidateWithoutTouch: FC = ({ children }) => (
  <ValidateWithoutTouchContext.Provider value>
    {children}
  </ValidateWithoutTouchContext.Provider>
);

export const useValidateWithoutTouch = () => useContext(ValidateWithoutTouchContext);
export const useIsError = (meta: Pick<FieldMetaProps<unknown>, "error" | "touched">) =>
  useContext(ValidateWithoutTouchContext)
    ? !!meta.error
    : !!meta.touched && !!meta.error
;
