import { ApiCartModel } from "@ecommerce/core/generated";
import { GhostCart, isGhostCart } from "components/context/AppContext/contexts";

const getCartItemCount = (cart: ApiCartModel | GhostCart): string | undefined => {
  if (isGhostCart(cart)) {
    return undefined;
  }

  const count = cart.items.reduce((acc, x) => acc + x.quantity, 0);

  if (count > 0) {
    return count > 9 ? "9+" : `${count}`;
  }

  return undefined;
};

export default getCartItemCount;
