import { FC } from "react";
import RobeHeading from "components/ui/RobeHeading";
import { Modal } from "anolis-ui";
import CreateAccountBeforeOrder from "components/ui/CreateAccountBeforeOrder";
import LostPasswordForm from "components/forms/LostPassword";
import { FormattedMessage } from "react-intl";

export interface LostPasswordModalProps {
  username: string;
}

const LostPasswordModal: FC<LostPasswordModalProps> = ({ username }) => {
  return (
    <Modal
      header={(
        <RobeHeading t="h3" afterContent="?" fontWeight={700}>
          <FormattedMessage id="components.forgottenPassword.title" defaultMessage="Forgot your password" />
        </RobeHeading>
      )}
      px="2rem"
    >
      <LostPasswordForm username={username} />
      {/* <CreateAccountBeforeOrder /> TODO: uncomment once B2B anb B2C accounts recognisible */}
    </Modal>
  );
};

export default LostPasswordModal;
