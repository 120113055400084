import { FC } from "react";
import RobeHeading from "components/ui/RobeHeading";
import { x } from "@xstyled/emotion";
import Link from "next/link";
import { Button, useModal } from "anolis-ui";
import UserAddIcon from "components/icons/24/user-add.svg";
import { SystemProps } from "@xstyled/system";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

interface FirstTimeShoppingProps extends SystemProps {}

const CreateAccountBeforeOrder: FC<FirstTimeShoppingProps> = ({ ...props }) => {
  const [close] = useModal();
  const { formatMessage } = useIntl();

  return (
    <x.div
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderTop="1px solid rgba(147, 149, 152, 0.5)"
      pt="2rem"
      mt="2.5rem"
      {...props}
    >
      <RobeHeading t="h3" afterContent="?">
        <FormattedMessage id="createAccount.title" defaultMessage="Shopping for the first time" />
      </RobeHeading>

      <x.p mt="1rem" textAlign="center">
        <FormattedMessage id="createAccount.description" defaultMessage="To continue with your purchase, please create an account." />
      </x.p>

      <Link href="/registration" passHref>
        <Button as="a" s="sm" leftIcon={<UserAddIcon />} mt="1.5rem" onClick={() => close()}>
          {formatMessage(content.button)}
        </Button>
      </Link>
    </x.div>
  );
};

export default CreateAccountBeforeOrder;

const content = defineMessages({
  button: {
    id: "createAccount.button",
    defaultMessage: "Create new account"
  }
});
