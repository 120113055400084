import { FC } from "react";
import SVGInstagram from "components/icons/32/instagram.svg";
import styled, { x } from "@xstyled/emotion";
import Link from "next/link";
import { Url } from "url";
import { Typography } from "anolis-ui";
import { FormattedMessage } from "react-intl";

const IconCard: FC<IconCardProps> = ({ ...props }) => {
  return (
    <Link type="simple" target="_blank" {...props}>
      <x.div style={{ aspectRatio: "1/1", cursor: "pointer" }} overflow="hidden" position="relative">
        <x.div
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          backgroundColor="robe"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <SVGInstagram style={{ display: "inline-block", margin: ".5rem" }} />
          <Typography
            fontFamily="Avenir"
            color="#fff"
            fontSize={{ sm: "1rem", md: "1rem", lg: "1.3rem", xl: "1.5rem" }}
            margin=".5rem"
          >
            <FormattedMessage id="instagram.title" defaultMessage="@robelighting" />
          </Typography>
        </x.div>
      </x.div>
    </Link>
  );
};

export default IconCard;

export type IconCardProps = {
  target?: string;
  type?: string;
  href: Url | string;
};
