import { Localizable } from "@ecommerce/core/src";
import { useRouter } from "next/router";
import { useMemo } from "react";

export const useLocal = () => {
  const { locale: _l } = useRouter();
  const locale = _l ?? "en";

  return useMemo(() => ({
    localize: localize(locale)
  }), [locale]);
};

export const localize = (locale: string) => <T>(x: Localizable<T>): T | undefined =>
  x && typeof x === "object" ? (x as any)[locale] : x; // as any is ok here because x does not to have locale key
