import { x } from "@xstyled/emotion";
import { Collapse, Icon, TextLink, useModal } from "anolis-ui";
import { useUser } from "components/context/AppContext/contexts";
import { MenuContext } from "components/context/MenuContext";
import DropIcon from "components/icons/12/drop.svg";
import UserIcon from "components/icons/24/user.svg";
import LoginModal from "components/modals/Login";
import Link from "next/link";
import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import RobeCreditIcon from "components/icons/32/robecredit.svg";
import Price from "components/ui/Price";
import useUserType from "utils/useUserType";

interface MobileNavigationProps {
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

const MobileNavigation: FC<MobileNavigationProps> = ({ open, setOpen }) => {
  const menuContext = useContext(MenuContext);
  const [openCollapse, setOpenCollapse] = useState<number | undefined>(undefined);
  const [openSubCollapse, setOpenSubCollapse] = useState<number>(0);
  const [openLogin] = useModal(LoginModal);
  const user = useUser();
  const userType = useUserType();

  useEffect(() => {
    setOpenSubCollapse(0);
  }, [openCollapse]);

  useEffect(() => {
    if (open) {
      document.body.setAttribute("style", "overflow: hidden");
    } else {
      document.body.setAttribute("style", "overflow: auto");
    }
  }, [open]);

  return (
    <x.div
      display={{ _: open ? "flex" : "none", lg: "none" }}
      position="absolute"
      bottom={0}
      w="100%"
      h={{ _: "calc((var(--vh, 1vh) * 100) - 4rem)" }}
      bg="#fff"
      top="4rem"
      left={0}
      flexDirection="column"
      justifyContent="space-between"
      overflowY="scroll"
    >
      <x.div display="flex" flexDirection="column" justifyContent="flex-start" w="100%" pt="0.5rem">
        {menuContext?.menu?.map((menuItem, i) => (
          <x.div
            display="flex"
            key={menuItem.data.id}
            flexDirection="column"
          >
            <x.div
              display="flex"
              justifyContent="space-between"
              px="1.5rem"
              py="1rem"
            >
              <Link href={`/category/${menuItem.data.slug.toLowerCase()}`} passHref>
                <TextLink
                  fontWeight={700}
                  textTransform="uppercase"
                  fontSize="1rem"
                  lineHeight="1.5rem"
                  color="#000"
                  onClick={() => setOpen?.(false)}
                >
                  {menuItem.data.name}
                </TextLink>
              </Link>

              <x.div
                m="-1rem"
                p="1rem"
                onClick={() => setOpenCollapse(openCollapse === i ? undefined : i)}
                transform={openCollapse === i ? "rotate(180deg)" : 0}
                transition="transform 300ms"
              >
                <Icon svg={<DropIcon />} />
              </x.div>
            </x.div>

            <Collapse open={openCollapse === i}>
              <x.div
                bg={openCollapse === i ? "black" : "#fff"}
                transition="transform 300ms"
              >
                {menuItem.children.map((item, k) => (
                  <x.div
                    key={item.data.id}
                    display="flex"
                    flexDirection="column"
                    px="1.5rem"
                    gap="1rem"
                  >
                    <x.div
                      pt={k ? "0.5rem" : "1rem"}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Link href={`/category/${item.data.slug.toLowerCase()}`} passHref>
                        <TextLink
                          textTransform="uppercase"
                          color={openCollapse === i ? "#fff" : "black"}
                          fontWeight={700}
                          textDecoration="none"
                          fontSize="1rem"
                          lineHeight="1.5rem"
                          onClick={() => setOpen?.(false)}
                        >
                          {item.data.name}
                        </TextLink>
                      </Link>

                      <x.div
                        m="-1rem"
                        p="1rem"
                        onClick={() => setOpenSubCollapse(k)}
                        transform={openSubCollapse === k ? "rotate(180deg)" : 0}
                        transition="transform 300ms"
                      >
                        <Icon
                          svg={<DropIcon />}
                          fill="#fff"
                        />
                      </x.div>

                    </x.div>

                    <Collapse open={openSubCollapse === k}>
                      <x.div display="flex" gap="0.5rem" flexDirection="column" mb="1rem">
                        {item.children.map((item2) => (
                          <Link href={`/category/${item2.data.slug.toLowerCase()}`} passHref key={item2.data.id}>
                            <TextLink
                              color={openCollapse === i ? "#fff" : "black"}
                              fontSize="1rem"
                              lineHeight="1.5rem"
                              fontWeight={400}
                              onClick={() => setOpen?.(false)}
                            >
                              {item2.data.name}
                            </TextLink>
                          </Link>
                        ))}
                      </x.div>
                    </Collapse>
                  </x.div>
                ))}
              </x.div>
            </Collapse>
          </x.div>
        ))}
      </x.div>

      {user.loggedIn ? (
        <x.div borderTop="1px solid rgba(147 ,149, 152, 0.5)">
          {userType === "b2b" && (
            <x.div display="flex" alignItems="center" px="1.5rem" py="1rem">
              <Icon svg={<RobeCreditIcon />} mr="1rem" />
              <x.p fontWeight={700} textTransform="uppercase">
                <x.div display="flex" justifyContent="center">
                  <FormattedMessage id="navigation.mobile.loggedIn.credits" defaultMessage="Robe credits:" />
                  <Price ml="0.2rem" value={Math.floor(user.user?.customer?.credit ?? 0)} fontSize="1rem" />
                </x.div>
              </x.p>
            </x.div>
          )}
          <x.div borderTop="1px solid rgba(147 ,149, 152, 0.5)">
            <Link href="/account" passHref>
              <x.div display="flex" alignItems="center" px="1.5rem" py="1rem">
                <Icon svg={<UserIcon />} mr="1rem" />

                <x.p fontWeight={700} textTransform="uppercase">
                  <FormattedMessage id="navigation.mobile.loggedIn.text" defaultMessage="My account" />
                </x.p>
              </x.div>
            </Link>
          </x.div>
        </x.div>
      ) : (
        <x.div
          display="flex"
          alignItems="center"
          borderTop="1px solid rgba(147 ,149, 152, 0.5)"
          px="1.5rem"
          py="1rem"
          onClick={() => openLogin({ setNavOpen: setOpen })}
        >
          <Icon svg={<UserIcon />} mr="1rem" />

          <x.p fontWeight={700} textTransform="uppercase">
            <FormattedMessage id="navigation.mobile.loggedOut.text" defaultMessage="User login" />
          </x.p>
        </x.div>
      )}
    </x.div>
  );
};

export default MobileNavigation;
