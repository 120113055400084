import { ResponseError } from "@ecommerce/core/src";
import { FormikHelpers } from "formik";

const mapErrors = (error: ResponseError | undefined | string, helpers: FormikHelpers<any>) => {
  helpers.setSubmitting(false);

  if (typeof error === "string") {
    helpers.setStatus({ type: "error", message: "Bad request." });
    return;
  }

  if (error?.errors) {
    helpers.setStatus({ type: "error", message: Object.values(error?.errors).join("; ") });
    return;
  }

  if (error?.title) {
    helpers.setStatus({ type: "error", message: error.title });
  }

  console.warn(error);
};

export default mapErrors;
