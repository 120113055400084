import { queryProductsRequest } from "@ecommerce/core/generated";
import { useRequest } from "@ecommerce/core/src/useRequest";
import { useUp, x } from "@xstyled/emotion";
import { SystemProps } from "@xstyled/system";
import { Button, Input } from "anolis-ui";
import SearchIcon from "components/icons/24/search.svg";
import SearchDropdown from "components/ui/TopBar/dropdowns/Search";
import { useRouter } from "next/router";
import React, { ChangeEvent, FC, KeyboardEvent, RefObject, useEffect, useMemo, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

interface SearchProps extends SystemProps {
  searchElementRef: RefObject<HTMLDivElement>;
  withResults?: boolean;
  autoFocus?: boolean;
  inTopBar?: boolean;
}

const Search: FC<SearchProps> = (
  {
    searchElementRef,
    withResults,
    autoFocus,
    inTopBar,
    ...props
  }) => {
  const inputElement = useRef<HTMLDivElement>(null);
  const [term, setTerm] = useState("");
  const router = useRouter();
  const upLg = useUp("lg");
  const [openDropdown, setOpenDropdown] = useState(false);
  const { formatMessage } = useIntl();
  const [isBeforeSubmit, setIsBeforeSubmit] = useState<boolean>(true);
  const showResultsDropdown = openDropdown && withResults && upLg && isBeforeSubmit;

  useEffect(() => {
    setTerm(router.query.term ? router.query.term as string : "");
  }, [router.query.term]);

  useEffect(() => {
    autoFocus && inputElement?.current?.querySelector("input")?.focus();
  }, [autoFocus]);

  useEffect(() => {
    setIsBeforeSubmit(true);
  }, [term]);

  const submitSearch = () => {
    router.push({ pathname: "/search", query: { term } });
    setIsBeforeSubmit(false);
  };

  const { data } = useRequest(typeof term === "string" && upLg && !!term && term.length > 2 ? [`${term}`] : null, queryProductsRequest);
  const category = data?.filter(p => p.category)?.[0]?.category; // TODO: BE should return this in a better structure
  const categoryPath = data?.filter(cp => cp.categoryPath)?.[0]?.categoryPath;

  const products = useMemo(() =>
    data?.filter(p => p.variant).map(p => ({
      id: p.variant!.id,
      name: p.variant!.product.name,
      categories: p.variant!.categories,
      price: p.variant!.price as number,
      slug: p.variant!.product.slug!,
      cover: p.variant?.picture
    })),
  [data]);

  return (
    <x.div
      ref={searchElementRef}
      position={inTopBar ? "absolute" : "relative"}
      zIndex="1000"
      display="flex"
      pointerEvents="all"
      w={{ _: "100%", lg: "28rem", xl: "41rem" }}
      h={inTopBar ? "auto" : "auto"}
      left="50%"
      transform="translateX(-50%)"
      p={inTopBar ? { _: "1rem", lg: "0.5rem" } : 0}
      top={inTopBar ? { _: "4rem", lg: "0" } : 0}
      borderTop={inTopBar ? { _: "1px solid rgba(147, 149, 152, 0.5)", lg: "none" } : "none"}
      bg="#fff"
      boxShadow={inTopBar ? { _: "0 0.5rem 1rem 0 rgba(30, 30, 30, 0.1), 0 1rem 2rem 0 rgba(30, 30, 30, 0.1)", sm: 0 } : 0}
      {...props}
    >
      <Input
        p={inTopBar && "0.6875rem"}
        w="100%"
        ref={inputElement as RefObject<HTMLInputElement>}
        placeholder="Search..."
        leftIcon={<SearchIcon />}
        _leftIcon={{ fill: "gray", display: { _: "none", sm: "flex" } }}
        _input={{
          padding: 0,
          value: term,
          onChange: (e: ChangeEvent<HTMLInputElement>) => setTerm(e.target.value),
          onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => e.key === "Enter" ? submitSearch() : e.key === "Escape",
          onFocus: () => setOpenDropdown(true),
          onBlur: () => setTimeout(() => setOpenDropdown(false), 150)
        }}
      />

      <Button as="a" px="1.5rem" s="sm" onClick={submitSearch}>
        <span>
          {formatMessage(content.button)}
        </span>
      </Button>

      {showResultsDropdown && products &&
      <SearchDropdown products={products} category={category} categoryPath={categoryPath} term={term} />}
    </x.div>
  );
};

export default Search;

export const useSearchClickOutside = (searchElementRef: RefObject<HTMLDivElement>,
  buttonRef: RefObject<HTMLDivElement>,
  close: () => unknown) => {
  useEffect(() => {
    const closeSearch = (e: Event) => {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      const isInside = searchElementRef.current?.contains(e.target as Node) || buttonRef.current?.contains(e.target as Node);
      !isInside && close();
    };

    document.addEventListener("click", closeSearch);

    return () => {
      document.removeEventListener("click", closeSearch);
    };
  }, [buttonRef, close, searchElementRef]);
};

const content = defineMessages({
  button: {
    id: "components.topBar.searchDropdown.button",
    defaultMessage: "Search"
  }
});
