import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import { useField } from "formik";
import { x } from "@xstyled/emotion";
import { Icon, Input, TextLink } from "anolis-ui";
import { TextFieldProps } from "components/formik/TextField";
import HideIcon from "components/icons/16/hide.svg";
import ShowIcon from "components/icons/16/show.svg";
import Label from "components/formik/Label";

type PasswordFieldProps = TextFieldProps;

const PasswordField: FC<PasswordFieldProps> = ({ ...props }) => {
  const [field, meta] = useField(props.name);
  const isError = meta.touched && meta.error !== undefined;
  const inputElement = useRef<HTMLDivElement>(null);

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    inputElement?.current?.querySelector("input")?.focus();
  }, []);

  return (
    <Label {...props}>
      <x.div
        fontFamily="avenir"
        fontWeight={600}
        fontSize="0.875rem"
        lineHeight="1.3125"
        mb="0.25rem"
        {...props._labelText}
        {...isError && { color: "robe" }}
      >
        {props.label}
        {props.labelText && (
          <x.div display="flex" justifyContent="space-between">
            <x.span>
              {props.labelText} {props.required && <x.span color="robe" ml="0.25rem">*</x.span>}
            </x.span>

            <x.div display="flex" alignItems="center">
              <Icon svg={showPassword ? <HideIcon /> : <ShowIcon />} fill="robe" mr="0.4rem" />

              <TextLink
                v="underlined"
                fontSize="inherit"
                lineHeight="inherit"
                fontWeight={400}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"}
              </TextLink>
            </x.div>
          </x.div>
        )}
      </x.div>

      <Input
        ref={props.autofocus ? inputElement as RefObject<HTMLInputElement> : null}
        _input={{ ...{ type: showPassword ? "text" : "password" }, ...field }}
        {...props.inputProps}
      />
    </Label>
  );
};

export default PasswordField;
