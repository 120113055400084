import { x } from "@xstyled/emotion";
import { FC } from "react";

interface BurgerMenuProps {
  isOpen?: boolean;
  onClick?: () => void;
}

const BurgerMenu: FC<BurgerMenuProps> = ({ isOpen, onClick }) => {
  return (
    <x.div
      display={{ _: "flex", lg: isOpen ? "flex" : "none" }}
      flexDirection="column"
      justifyContent="center"
      pr={{ _: "1rem", lg: "0.1875rem" }}
      pl={{ _: isOpen ? "0.55rem" : "0.7rem", lg: "0.1875rem" }}
      onClick={onClick}
    >
      <x.div
        {...LineStyle}
        mt="0"
        {...isOpen && { transform: "rotate(45deg) translateY(5px) translateX(5px)", w: "1.4rem" }}
      />
      <x.div
        {...LineStyle}
        w="1rem"
        ml="0.2rem"
        {...isOpen && { transform: "translateX(-1rem)", opacity: 0 }}
      />
      <x.div
        {...LineStyle}
        {...isOpen && { transform: "rotate(-45deg) translateY(-4px) translateX(3px)", w: "1.4rem" }}
      />
    </x.div>
  );
};

const LineStyle = {
  w: "1.25rem",
  h: "2px",
  bg: "black",
  mt: "4px",
  borderRadius: "2px",
  transition: "opacity 300ms, transform 300ms"
};

export default BurgerMenu;
