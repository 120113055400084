import { x } from "@xstyled/emotion";
import { Label } from "anolis-ui";
import { useSharedProps } from "components/context/AppContext/contexts";
import SelectField from "components/formik/SelectField";
import { Form, Formik } from "formik";
import { useRouter } from "next/router";
import { FC, useRef } from "react";
import { FormattedMessage } from "react-intl";
import useOutsideAlerter from "utils/useOutsideAlerter";

export const localeList = [
  { name: "English", code: "en" } // TODO: uncomment for other locales
  // { name: "Deutsch", code: "de" },
  // { name: "русский", code: "ru" }
];

const LocaleDropdown: FC<{ setOpen: (x: boolean) => unknown }> = ({ setOpen }) => {
  const { locale, push, pathname, query, asPath } = useRouter();
  const { _shared, setCurrency, currency } = useSharedProps();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setOpen);

  const currencies = _shared.currencies.map(c => {
    return {
      name: `${c.code} (${c.symbol})`,
      value: c.code
    };
  });

  const locales = localeList.map(l => {
    return {
      name: `${l.name} (${l.code.toUpperCase()})`,
      value: l.code
    };
  });

  const changeLocale = async (localeCode: string) => {
    await push(
      {
        pathname: pathname,
        query: query
      },
      asPath,
      {
        locale: localeCode
      }
    );
  };

  return (
    <x.div
      position="fixed"
      top="4rem"
      w={{ _: "100%", md: "16rem" }}
      bg="#fff"
      boxShadow="0 0.5rem 1rem 0 rgba(30, 30, 30, 0.1), 0 1rem 2rem 0 rgba(30, 30, 30, 0.1)"
      right={0}
      backgroundColor="#fff"
      maxHeight="calc(100vh - 4rem)"
      display="flex"
      flexDirection="column"
      borderTop="1px solid rgba(147, 149, 152, 0.5)"
      zIndex={1}
      padding="1rem"
      ref={wrapperRef}
      fontFamily="avenir"
    >
      <Formik
        initialValues={{ locale, currency: currency.code }}
        onSubmit={() => {}}
      >
        <Form>
          <x.div>
            <Label>
              <FormattedMessage id="components.topBar.localeDropdown.locale" defaultMessage="Locale" />
            </Label>
            <SelectField name="locale" options={locales} onChange={changeLocale} />
          </x.div>

          {/* TODO: uncomment with currency switch feature
          <x.div mt="1rem">
            <Label>
              <FormattedMessage id="components.topBar.localeDropdown.currency" defaultMessage="Currency" />
            </Label>
            <SelectField name="currency" options={currencies} onChange={setCurrency} />
          </x.div> */}
        </Form>
      </Formik>
    </x.div>
  );
};

export default LocaleDropdown;
