
import { x } from "@xstyled/emotion";
import { FC } from "react";
import InstaCard from "components/ui/Instagram/InstaCard";
import IconCard from "components/ui/Instagram/IconCard";
import insta1 from "public/static/img/instagram/insta1.jpg";
import insta2 from "public/static/img/instagram/insta2.jpg";
import insta3 from "public/static/img/instagram/insta3.jpg";
import insta4 from "public/static/img/instagram/insta4.jpg";
import insta5 from "public/static/img/instagram/insta5.jpg";

const InstaStrip: FC = () => {
  return (
    <x.div display="grid" gridTemplateColumns={{ _: "repeat(2, 1fr)", sm: "repeat(6, 1fr)" }} gap="0">
      <InstaCard href="https://www.instagram.com/p/CdV20mcLoTX/" image={insta1} alt="Instagram Photo" />
      <IconCard href="https://www.instagram.com/robelighting/" />
      <InstaCard href="https://www.instagram.com/p/CfUBIAduT9J/" image={insta2} alt="Instagram Photo" />
      <InstaCard href="https://www.instagram.com/p/Cdx-mmBu2V7/" image={insta3} alt="Instagram Photo" />
      <InstaCard href="https://www.instagram.com/p/Cd72S-nI1zO/" image={insta4} alt="Instagram Photo" />
      <InstaCard href="https://www.instagram.com/p/CfEStQ9OZKs/" image={insta5} alt="Instagram Photo" />
    </x.div>
  );
};

export default InstaStrip;
