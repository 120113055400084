import { ElementType, FC, ForwardedRef, forwardRef, RefObject } from "react";
import { Icon, IconProps, Txt } from "anolis-ui";

interface IconBoxProps extends IconProps {
  badge?: string;
  as?: ElementType | undefined;
  onClick?: (() => void) | ((props: {}) => unknown);
  isActive?: boolean;
  ref?: ForwardedRef<HTMLDivElement>;
}

const IconBox: FC<IconBoxProps> = forwardRef(({ children, badge, as, onClick, isActive, mt, ml, ...props }, ref) => {
  return (
    <Icon
      display="flex"
      px={{ _: "0.75rem", lg: "1.25rem" }}
      py="1.25rem"
      bg={isActive ? "black" : "#fff"}
      svg={children}
      fill={isActive ? "#fff" : "black"}
      fillHover="robe"
      textDecoration="none"
      alignItems="center"
      position="relative"
      cursor="pointer"
      as={as}
      ref={ref as RefObject<HTMLDivElement>}
      onClick={onClick}
      {...props}
    >
      {badge && (
        <Txt
          t="p"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          color="#fff"
          fontSize="0.625rem"
          lineHeight="1rem"
          fontWeight="700"
          w="1rem"
          h="1rem"
          bg="robe"
          borderRadius="1rem"
          ml={ml}
          mt={mt}
        >
          {badge}
        </Txt>
      )}
    </Icon>
  );
});

IconBox.displayName = "IconBox";

export default IconBox;
