import React, { FC } from "react";
import { useFormikContext } from "formik";
import { Button, ButtonProps } from "anolis-ui";

const SubmitButton: FC<ButtonProps> = (props) => {
  const { isSubmitting } = useFormikContext();

  return <Button type="submit" loading={isSubmitting} {...props} />;
};

export default SubmitButton;
